import React, { useState } from "react";
import Modal from "react-modal";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

Modal.setAppElement("#root");

const ImageViewer = ({ imageUrl }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className="flex flex-col items-center">
      {/* Thumbnail that opens the modal */}
      <img
        src={imageUrl}
        alt="Thumbnail"
        className="w-20 h-20 object-cover cursor-pointer rounded-lg shadow-lg"
        onClick={() => setModalIsOpen(true)}
      />

      {/* Modal for Image Viewing */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Image Viewer"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80"
        overlayClassName="fixed inset-0 bg-black bg-opacity-70"
      >
        <div className="relative bg-white p-4 rounded-lg">
          <button
            className="absolute top-2 right-2 text-white bg-red-500 rounded-full px-3 py-1"
            onClick={() => setModalIsOpen(false)}
          >
            ✕
          </button>
          {/* Zoomable Image */}
          <Zoom>
            <img src={imageUrl} alt="Zoomable" className="max-w-full max-h-screen rounded-lg" />
          </Zoom>
        </div>
      </Modal>
    </div>
  );
};

export default ImageViewer;
